  /** HEADER */
  
  /** NAVIGATION BAR */
.title-font{
  font-family: 'Courgette', cursive;
}

.title-color{
  color: #192350;
  // color: #FF0000;
}